<template>
  <v-container class="home-hero" fluid fill-height  style="min-height: 70vh">
    <!-- <v-layout justify-center align-center column  pa-5>
      <div class="hidden-sm-and-down">
        <div class="display-3 font-weight-black white--text text-center">
          <span style="color: #d6ebff; text-shadow: 1.2px 1.2px 1.75px #001529"
            >Premium Scaffolding Equipment</span
          >
        </div>
        <div class="display-3 font-weight-black white--text text-center mb-3">
          <span style="color: #d6ebff; text-shadow: 1.2px 1.2px 1.75px #001529"
            >and Scaffolding Services</span
          >
        </div>
        <div class="display-1 font-weight-bold white--text text-center">
          <span style="color: #f1faee"
            >Full-feature scaffolding enterprise that puts safety and sturdiness
            first</span
          >
        </div>
      </div>
      <div class="hidden-md-and-up">
        <div class="display-2 font-weight-black white--text text-xs-center">
          <span style="color: #d6ebff; text-shadow: 1.2px 1.2px 1.75px #001529"
            >Premium Scaffolding Equipment</span
          >
        </div>
        <div
          class="display-2 font-weight-black white--text text-xs-center mb-3"
        >
          <span style="color: #d6ebff; text-shadow: 1.2px 1.2px 1.75px #001529"
            >and Scaffolding Services</span
          >
        </div>
        <div class="display-1 font-weight-bold white--text text-xs-center">
          <span style="color: #f1faee"
            >Full-feature scaffolding enterprise that puts safety and sturdiness
            first</span
          >
        </div>
      </div>
      <v-btn fab class="mt-5" color="#D6EBFF">
        <v-icon small color="#005CB5">mdi-arrow-collapse-down</v-icon>
      </v-btn>
    </v-layout> -->
    <v-container d-flex >
      <v-row  class="justify-center align-center ">
        <v-col cols="12" md="6">
          <v-container>
         <div >
             <div class="display-1 font-weight-black white--text text-left" style="text-shadow: 2px 2px 8px #000000">
          <span 
            >Welcome to <span class="display-2" style="color:#c00102;font-family: BIRDMAN !important ; font-weight:750"> CONCRAFT</span>,   </span
          >
        </div>
        <div class="display-1 font-weight-black white--text text-left" style="text-shadow: 2px 2px 8px #000000">
          <span 
            >Premium Scaffolding Equipment</span
          >
        </div>
        <div class="display-1 font-weight-black white--text text-left mb-3" style="text-shadow: 2px 2px 8px #000000"> 
          <span 
            >and Scaffolding Services</span
          >
        </div>
        <div class="headline font-weight-bold white--text text-left" style="text-shadow: 2px 2px 8px #000000">
          <span style="color:  #d6ebff"
            >Full-feature scaffolding enterprise that puts safety and sturdiness
            first</span
          >
        </div>
      </div>
          </v-container>
        </v-col>
        <v-col justify="center" align="center" cols="12" md="6">
          <v-img max-width="90vw" contain
            src="https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/HOMEPART.webp?alt=media&token=8cde65d7-ac9c-4abf-9015-2f437eea6c10"
          >
          </v-img>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "HomeHero",
};
</script>

<style scoped>
.hero {
display: block;
box-sizing: border-box;
height: 500px;
background-color: #3d99dc;
clip-path: ellipse(162% 100% at 39.92% 0%);
}
.home-hero {
  background: url("https://images.unsplash.com/photo-1543412970-5673add7e407?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80");
  background-size: cover;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 0 1000px rgba(0, 43, 99, 0.2);
  clip-path: ellipse(162% 100% at 39.92% 0%);
}
.gradient-text {
  background-image: linear-gradient(60deg, rgb(3, 124, 230), rgb(117, 177, 230));
  background-clip: text;
  color: transparent;
}
</style>

<template>
  <v-container fluid
      fill-height class="pa-0 ma-0">
    <v-container d-flex style="min-height: 65vh">
      <!-- Desktop -->
      <v-row class="justify-center align-center">
        <v-col cols="12" md="9">
          <v-flex class="display-1 text-xs-center my-5" style="color: #d6ebff"
            >Our
            <span
              class="font-weight-black"
              style="font-weight: 1200; color: #c00102; font-family: BIRDMAN"
              >Premium </span
            >Services</v-flex
          >
          <v-row>
            <v-col align="center" justify="center" cols="12" md="6" lg="4">
              <v-card
                max-width="425px"
                class="d-flex flex-column text-left"
                height="100%"
              >
                <v-img
                  max-height="130px"
                  src="https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/g2407.webp?alt=media&token=e5977898-0274-42a4-9eac-ddef0e41b668"
                ></v-img>
                <v-card-title
                  class="headline mt-3 font-weight-bold"
                  style="color: #c00102"
                >
                  Premium Scaffolding Equipment
                </v-card-title>
                <v-card-text class="subheading mt-3">
                  Premium scaffolding equipment for hire to the client at
                  affordable prices. We source our scaffolding from either
                  manufacturers or from older scaffolding companies where we
                  take their used scaffolding and refurbish them, thereby
                  recycling old materials and providing a product that has been
                  refurbished and is certified to be work and construction
                  ready. Concraft only pushes quality in the form of their
                  scaffolding.
                </v-card-text>
                <v-spacer />
                <v-card-actions min-height="100%">
                  <v-btn to="/about" dark elevation="0" color="#008FE8"
                    >Read More</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col align="center" justify="center" cols="12" md="6" lg="4">
              <v-card
                max-width="425px"
                class="d-flex flex-column"
                height="100%"
              >
                <v-img
                  max-height="130px"
                  src="https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/scaffolinghero2.webp?alt=media&token=3e43ea35-c203-4d5b-81a9-1737d9596b36"
                ></v-img>
                <v-card-title
                  class="headline mt-3 font-weight-bold text-left"
                  style="color: #c00102"
                >
                  Scaffolding Services & Labour
                </v-card-title>
                <v-card-text class="subheading mt-3 text-left">
                  Concraft sources only the most qualified and most experienced
                  labour one can find on the market at the moment. We go through
                  all the important safety checks when screening our labourers
                  and make sure they are fully qualified and certified from
                  inception. That is the Concraft Guarantee
                </v-card-text>
                <v-spacer />
                <v-card-actions>
                  <v-btn to="/services" dark elevation="0" color="#008FE8"
                    >Read More</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col align="center" justify="center" cols="12" md="6" lg="4">
              <v-card
                height="100%"
                max-width="425px"
                class="d-flex flex-column text-left"
              >
                <v-img
                  max-height="130px"
                  src="https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/scaffolinghero3.webp?alt=media&token=38b59658-ba4e-4742-ae59-5c3ab4104c88"
                ></v-img>
                <v-card-title
                  class="headline mt-3 font-weight-bold"
                  style="color: #c00102; word-break: break-word"
                >
                  Scaffolding Placing Assessments
                </v-card-title>
                <v-card-text class="subheading mt-3">
                  We offer free scaffolding assessments and scaffolding placing
                  assessments free of charge to the customer. This process is
                  usually out-sourced along with labour as the process can take
                  long for an individual company to take on with each new
                  project or endeavour they want to pursue.
                </v-card-text>
                <v-spacer />
                <v-card-actions>
                  <v-btn to="/services" dark elevation="0" color="#008FE8"
                    >Read More</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-img class="position"></v-img>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
.position {
  z-index: -1;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20vh;
  position: absolute;
  background: url("https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/black.webp?alt=media&token=34456b33-a2ea-4e76-8a5f-3574ddf33fd6");
  background-size: contain;
}
</style>

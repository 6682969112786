<template>
  <div>
    <HomeHero />
    <HomeDetails />
    <HomeRefs/>
    
  </div>
</template>

<script>
import HomeHero from "@/components/HomeHero";
import HomeDetails from "@/components/HomeDetails";
import HomeRefs from "@/components/HeroRefs";

export default {
  name: "Home",

  components: {
    HomeHero,
    HomeDetails,
    HomeRefs
  },
};
</script>
